import React from 'react';
import { useLocation } from 'react-router-dom';

const LoginCheck = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const accessToken = searchParams.get('#id_token');
    const hostname = window.location.hostname;
    
    let redirectURL = '';
    if(hostname === 'trade.mimothi.com') {
        redirectURL = 'https://trade.auth.ap-south-1.amazoncognito.com/login?response_type=token&client_id=5amnufr9uatte9vr49lvctgg3u&redirect_uri=https://trade.mimothi.com/dashboard/';
    }
    else if(hostname === 'trade.okanava.com') {
        redirectURL = 'https://trade.auth.ap-south-1.amazoncognito.com/login?response_type=token&client_id=1nl98a0utgqqe3ej347s5rehov&redirect_uri=https://trade.okanava.com/dashboard/';
    } else {
        redirectURL = 'https://trade.auth.ap-south-1.amazoncognito.com/login?response_type=token&client_id=4qfdjqd88mrlrq4lql6jn8luln&redirect_uri=http://localhost:3000/Dashboard';
    }

    if(accessToken) {
        return (
            <div>
            <h1>Home</h1>
            {}
            </div>
        );
    } else {
        window.location.href = redirectURL;
    }
};

export default LoginCheck;