// src/Dashboard.js
import { useEffect } from 'react';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const { authToken, userGroups } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(userGroups)
    if (!authToken) {
      navigate('/');
    } else {
      if (userGroups.includes('TradeAdmin')) {
        navigate('/ahome');
      } else if (userGroups.includes('Operations')) {
        navigate('/orders');
      } else {
        navigate('/uhome');
      }
    }
  }, [authToken, userGroups, navigate]);

  return null;
};

export default Home;
