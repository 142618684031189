import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from './AuthContext';

const ProtectedRoute = ({ element, requiredGroups }) => {
  const { authToken, userGroups } = useAuth();
  const hasRequiredGroup = requiredGroups && requiredGroups.some(requiredGroup => userGroups.includes(requiredGroup));

  if (!authToken || (requiredGroups && !hasRequiredGroup)) {
    return <Navigate to="/" replace />;
  }

  return element;
};

export default ProtectedRoute;