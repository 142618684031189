import React, { useEffect, useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, TextField, useTheme, useMediaQuery } from '@mui/material';
import { useAuth } from './AuthContext';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonIcon from '@mui/icons-material/Person';

const AdminLogins = () => {
  const [logins, setLogins] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { authToken } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchLogins = async (term) => {
    try {
      const response = await fetch(process.env.REACT_APP_FETCH_LOGINS, {
        method: 'POST',
        headers: {
          origin: process.env.REACT_APP_ORIGIN,
          'Content-type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify({ searchTerm: term }),
      });
      const data = await response.json();
      setLogins(data);
    } catch (error) {
      console.error('Error fetching login data:', error);
    }
  };

  useEffect(() => {
    fetchLogins(searchTerm);
  }, [authToken, searchTerm]);

  const formatTime = (dateString) => {
    const options = { hour: '2-digit', minute: '2-digit', hour12: true };
    return new Date(dateString).toLocaleTimeString(undefined, options);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Logins
      </Typography>
      <TextField
        label="Search User"
        variant="outlined"
        fullWidth
        margin="normal"
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <Grid container spacing={2}>
        {logins.map((login, index) => (
          <Grid item xs={12} sm={6} md={4} key={login.loggedInAt}>
            <Card
              sx={{
                border: '2px solid',
                borderColor: index % 2 === 0 ? '#9370DB': '#A52A2A',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                },
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <PersonIcon sx={{ fontSize: '2rem', mb: 1 }} />
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                  {login.fullName}
                </Typography>
                <AccessTimeIcon sx={{ fontSize: '2rem', mb: 1 }} />
                <Typography sx={{ color: 'text.secondary' }}>
                  {formatTime(login.loggedInAt)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default AdminLogins;
