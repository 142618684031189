import React, { useState, useEffect } from 'react';
import { Typography, Card, CardContent, Grid, Button, Collapse, CardHeader, Container, Box, Paper } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, useTheme, useMediaQuery } from '@mui/material';
import { useAuth } from './AuthContext';

const UserOrders = () => {
  const [orders, setOrders] = useState([]);
  const [expandedOrderId, setExpandedOrderId] = useState(null);
  const [orderUpdates, setOrderUpdates] = useState({});
  const theme = useTheme();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const { authToken } = useAuth();

  useEffect(() => {
    const fetchOrdersAPI = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_FETCH_USER_OPEN_ORDERS, {
          method: 'GET',
          headers: {
            origin: process.env.REACT_APP_ORIGIN,
            'Content-type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
          }
        });
        const data = await response.json();
        setOrders(data);
      } catch (error) {
        console.error('Error fetching orders:', error);
      }
    };

    fetchOrdersAPI();
  }, []);

  const handleExpandClick = async (orderId) => {
    if (expandedOrderId === orderId) {
      setExpandedOrderId(null);
      setOrderUpdates((prev) => ({ ...prev, [orderId]: null }));
    } else {
      setExpandedOrderId(orderId);
      if (!orderUpdates[orderId]) {
        try {
            const orderDetails = {
                "orderID": orderId
            }
            const response = await fetch(process.env.REACT_APP_FETCH_USER_ORDER_UPDATES, {
                method: 'POST',
                headers: {
                    origin: process.env.REACT_APP_ORIGIN,
                    'Content-type': 'application/json',
                    'Authorization': `Bearer ${authToken}`,
                },
                body: JSON.stringify(orderDetails),
            });
            const data = await response.json();
            setOrderUpdates((prev) => ({ ...prev, [orderId]: data }));
        } catch (error) {
          console.error('Error fetching order updates:', error);
        }
      }
    }
  };

  const formatDate = (date) => {
    const options = { month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    const formatter = new Intl.DateTimeFormat('en-US', options);
  
    const parts = formatter.formatToParts(date);
    const day = date.getDate();
    const suffix = day % 10 === 1 && day !== 11 ? 'st' : day % 10 === 2 && day !== 12 ? 'nd' : day % 10 === 3 && day !== 13 ? 'rd' : 'th';
  
    return `${day}${suffix} ${parts.find(part => part.type === 'month').value} ${parts.find(part => part.type === 'year').value}, ${parts.find(part => part.type === 'hour').value}:${parts.find(part => part.type === 'minute').value} ${parts.find(part => part.type === 'dayPeriod').value}`;
  };

  const calculateDaysDifference = (startDate, endDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    return Math.round(Math.abs((endDate - startDate) / oneDay));
  };

  const formatToINR = (value) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
    }).format(value);
  };

  const calculateTotalInvested = (orders) => {
    return orders.reduce((total, order) => {
      return total + (order.quantity * order.priceAtCreate);
    }, 0);
  };

  const totalInvested = calculateTotalInvested(orders);

  const CardHeaderContent = ({ order }) => (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
                <Typography variant="h6"><strong>{order.tradingsymbol}</strong></Typography>
            </Grid>
            <Grid item xs={12} sm={6} container justifyContent={isSmallScreen ? 'flex-start' : 'flex-end'}>
                <Typography variant="h6" sx={{ alignSelf: 'center' }}>
                    Capital Used: <strong>{formatToINR(order.quantity * order.priceAtCreate)}</strong>
                </Typography>
            </Grid>
        </Grid>
    </Box>
  );

  return (
    <Container sx={{ mt: 4 }}>
      <Grid item xs={12} container alignItems="center" justifyContent="space-between">
        <Typography variant="h5" sx={{ mb: 4 }}>Open Orders</Typography>
        <Typography variant="h6" sx={{ mb: 4 }}>
          Total Capital: <strong>{formatToINR(totalInvested)}</strong>
        </Typography>
      </Grid>
      {orders.map((order) => (
        <Card
          key={order.orderID}
          sx={{
            mb: 3,
            border: '1px solid #ccc',
            borderRadius: 2,
            boxShadow: 3,
            padding: 2,
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            '&:hover': {
              transform: 'scale(1.05)',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
            },
          }}
        >
          <CardHeader title={<CardHeaderContent order={order} />} sx={{ borderBottom: '2px solid #000', p: 2 }} />
          <CardContent>
            <Grid container spacing={1} sx={{ mb: 1, borderRadius: 2 }}>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Exchange:</strong> {order.exchange}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Quantity:</strong> {order.quantity}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Order/Product Type:</strong> {order.orderType} / {order.productType}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Limit Price:</strong> {order.limitPrice}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Stop Loss:</strong> {order.stopLoss}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Target:</strong> {order.target}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Order Price:</strong> {order.priceAtCreate}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                  <strong>Order Created At:</strong> {formatDate(new Date(order.orderedAt))} (<strong>{calculateDaysDifference(new Date(order.orderedAt), new Date())}</strong> Days)
                </Typography>
              </Grid>
            </Grid>
            <Button onClick={() => handleExpandClick(order.orderID)} sx={{ marginTop: 2 }}>
              {expandedOrderId === order.orderID ? 'Hide Updates' : 'Show Updates'}
            </Button>
            <Collapse in={expandedOrderId === order.orderID} timeout="auto" unmountOnExit>
              {orderUpdates[order.orderID] ? (
                <TableContainer component={Paper} sx={{ mt: 2 }}>
                    <Table size="small" aria-label="order updates table">
                        <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
                        <TableRow>
                            <TableCell sx={{ borderBottom: '1px solid #ccc' }}>Updated At</TableCell>
                            <TableCell sx={{ borderBottom: '1px solid #ccc' }}>Update Type</TableCell>
                            <TableCell sx={{ borderBottom: '1px solid #ccc' }}>Quantity</TableCell>
                            <TableCell sx={{ borderBottom: '1px solid #ccc' }}>Order Type</TableCell>
                            <TableCell sx={{ borderBottom: '1px solid #ccc' }}>Limit Price</TableCell>
                            <TableCell sx={{ borderBottom: '1px solid #ccc' }}>Stop Loss</TableCell>
                            <TableCell sx={{ borderBottom: '1px solid #ccc' }}>Target</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {orderUpdates[order.orderID].map((update, index) => (
                            <TableRow key={index}>
                            <TableCell sx={{ borderBottom: '1px solid #ccc' }}>{formatDate(new Date(update.updatedAt))}</TableCell>
                            <TableCell sx={{ borderBottom: '1px solid #ccc' }}>{update.type}</TableCell>
                            <TableCell sx={{ borderBottom: '1px solid #ccc' }}>{update.quantity}</TableCell>
                            <TableCell sx={{ borderBottom: '1px solid #ccc' }}>{update.orderType}</TableCell>
                            <TableCell sx={{ borderBottom: '1px solid #ccc' }}>{update.limitPrice}</TableCell>
                            <TableCell sx={{ borderBottom: '1px solid #ccc' }}>{update.stopLoss}</TableCell>
                            <TableCell sx={{ borderBottom: '1px solid #ccc' }}>{update.target}</TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
              ) : (
                <Typography>Loading updates...</Typography>
              )}
            </Collapse>
          </CardContent>
        </Card>
      ))}
    </Container>
  );
};

export default UserOrders;

function formatToINR(amount) {
  return amount.toLocaleString('en-IN', {
    style: 'currency',
    currency: 'INR'
  });
}