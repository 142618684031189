import React, { useEffect, useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, TextField, Autocomplete, useTheme, useMediaQuery } from '@mui/material';
import { useAuth } from './AuthContext';
import SearchIcon from '@mui/icons-material/Search';

const TradeBook = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [trades, setTrades] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const { authToken } = useAuth();

  const fetchUsers = async (term) => {
    try {
      if (term.length >= 2) {
        const response = await fetch(process.env.REACT_APP_FETCH_USERS, {
          method: 'POST',
          headers: {
            origin: process.env.REACT_APP_ORIGIN,
            'Content-type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
          },
          body: JSON.stringify({ searchTerm: term }),
        });
        const data = await response.json();
        setUsers(data);
      } else {
        setUsers([]);
      }
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  useEffect(() => {
    fetchUsers(searchTerm);
  }, [authToken, searchTerm]);

  useEffect(() => {
    if (selectedUser) {
      const fetchTrades = async () => {
        try {
          const response = await fetch(process.env.REACT_APP_FETCH_TRADE_DATA, {
            method: 'POST',
            headers: {
              origin: process.env.REACT_APP_ORIGIN,
              'Content-type': 'application/json',
              'Authorization': `Bearer ${authToken}`,
            },
            body: JSON.stringify({ userID: selectedUser.userID }),
          });
          const data = await response.json();
          setTrades(data);
        } catch (error) {
          console.error('Error fetching trade data:', error);
        }
      };

      fetchTrades();
    } else {
      setTrades([]);
    }
  }, [selectedUser, authToken]);

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Trade Book
      </Typography>
      <Autocomplete
        options={users}
        getOptionLabel={(option) => option.fullName}
        onChange={(event, newValue) => {
          setSelectedUser(newValue);
        }}
        onInputChange={(event, newInputValue) => {
          setSearchTerm(newInputValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Search User"
            variant="outlined"
            fullWidth
            margin="normal"
            InputProps={{
              ...params.InputProps,
              startAdornment: (
                <>
                  <SearchIcon />
                  {params.InputProps.startAdornment}
                </>
              ),
            }}
          />
        )}
      />
      <Grid container spacing={2}>
        {trades.map((trade, index) => (
            <Grid item xs={12} key={trade.tradingsymbol}>
            <Card
                sx={{
                border: '2px solid',
                borderColor: index % 2 === 0 ? 'primary.main' : 'secondary.main',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                    transform: 'scale(1.05)',
                    boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                },
                }}
            >
                <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                }}
                >
                <Typography variant="h5" sx={{ fontWeight: 'bold', mb: 1 }}>
                    {trade.tradingsymbol}
                </Typography>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        <strong>Exchange:</strong> {trade.exchange}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        <strong>Quantity:</strong> {trade.quantity}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        <strong>Average Price:</strong> {trade.averageprice}
                    </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        <strong>Current Price:</strong> {trade.ltp}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        <strong>Total Buy Value:</strong> {(trade.quantity * trade.averageprice).toFixed(2)}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        <strong>Current Value:</strong> {(trade.quantity * trade.ltp).toFixed(2)}
                    </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        <strong>P&L:</strong> {trade.profitandloss}
                    </Typography>
                    <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                        <strong>P&L Percent:</strong> {trade.pnlpercentage}%
                    </Typography>
                    </Grid>
                </Grid>
                </CardContent>
            </Card>
            </Grid>
        ))}
        </Grid>
    </Container>
  );
};

export default TradeBook;
