import React, {useState, useEffect, useRef } from 'react';
import { useAuth } from './AuthContext';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

const UserHome = () => {
  const [tokenExists, setTokenExists] = useState(false);
  const [loading, setLoading] = useState(true);
  const [totp, setTotp] = useState('');
  const [totpError, setTotpError] = useState(false);
  const { authToken } = useAuth();
  const formRef = useRef(null);

  const handleTotpChange = (event) => {
    setTotp(event.target.value);
  };

  const fetchData = async () => {
      const response = await fetch(process.env.REACT_APP_GET_USER_AO_TOKENS, {
          method: 'GET',
          headers: {
              origin: process.env.REACT_APP_ORIGIN,
              'Content-type': 'application/json',
              'Authorization': `Bearer ${authToken}`,
          }
      });
      if (!response.ok) {
        throw new Error('Data coud not be fetched!')
      } else {
        return response.json()
      }
  }

  const submitForm = async (event) => {
    event.preventDefault();
    var form = document.getElementById('myForm');

    if (formRef.current.checkValidity() && totp.length === 6) {
      setLoading(true);
      const response = await fetch(process.env.REACT_APP_SAVE_USER_AO_TOKENS, {
        method: 'POST',
        headers: {
          origin: process.env.REACT_APP_ORIGIN,
          'Content-type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
        body: `{
            "totp":"${totp}"
          }`
      });
      if (!response.ok) {
        setTotpError(true)
        setTotp("")
        setLoading(false)
      } else {
        const data = await response.json()
        if (data.status === 'SUCCESS') {
          window.location.reload()
        } else {
          setTotpError(true)
          setLoading(false)
        }
      }
    } else {
        setTotpError(true)
    }
  }

  useEffect(() => {
    fetchData()
    .then((res) => {
        let responseData = JSON.parse(JSON.stringify(res));
        if(Array.isArray(responseData) && responseData.length > 0 && responseData[0].accessToken) {
            setTokenExists(true);
            setLoading(false);
        } else {
            setTokenExists(false);
            setLoading(false);
        }
    })
    .catch((e) => {
        setTokenExists(false);
        setLoading(false);
    });
  }, [])

  if(loading) {
    return (
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '60vh',
        }}
      >
        <Card
          sx={{
            width: 300,
            padding: 2,
            borderRadius: 8,
            boxShadow: 5,
          }}
        >
          <CardContent>
            <Typography variant="h1" sx={{ fontSize: 24, fontWeight: 'bold', mb: 2 }}>
              Broker Authentication
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress size={60} thickness={4} />
            </div>
          </CardContent>
        </Card>
      </Container>
    );
  }
  else if(tokenExists) {
      return (
        <Container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '60vh',
            backgroundColor: (theme) => theme.palette.background.default,
          }}
        >
          <Card
            sx={{
              width: 300,
              padding: 2,
              borderRadius: 8,
              boxShadow: 5,
              textAlign: 'center',
            }}
          >
            <CardContent>
              <Typography variant="h1" sx={{ fontSize: 24, fontWeight: 'bold', color: 'primary.main', mb: 5 }}>
                Broker Authentication
              </Typography>
              <Typography variant="body1" sx={{ fontSize: 18, color: 'text.primary' }}>
                We now have access to your broker APIs. Thank you...
              </Typography>
            </CardContent>
          </Card>
        </Container>
      );
  }
  else {
      return (
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '80vh',
            padding: '20px',
          }}
        >
          <Card
            sx={{
              width: '100%',
              maxWidth: 400,
              padding: 2,
              borderRadius: 8,
              boxShadow: 5,
              marginBottom: 3,
            }}
          >
            <CardContent>
              <Typography variant="h1" sx={{ fontSize: 24, fontWeight: 'bold', mb: 2 }}>
                Broker Authentication
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Enter TOTP from your Authenticator app to login.
              </Typography>
              <form ref={formRef} onSubmit={submitForm}>
                <TextField
                  variant="outlined"
                  label="Enter TOTP"
                  fullWidth
                  type="text"
                  inputProps={{ pattern: "[0-9]*" }}
                  onChange={handleTotpChange}
                />
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={submitForm}
                  sx={{ marginTop: 2 }}
                >
                  Login
                </Button>
              </form>
              <Typography variant="body2" sx={{ marginTop: 2 }}>
                Don't have an AngleOne account?{' '}
                <Link href="https://www.angelone.in/" target="_blank" rel="noopener">
                  Create an account
                </Link>
              </Typography>
            </CardContent>
          </Card>
        </Container>
      );
  }
};

export default UserHome;