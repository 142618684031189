import React, { useState, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';

const AdminHome = () => {
  const [companyQuery, setCompanyQuery] = useState('');
  const [companyNames, setCompanyNames] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [companyDetails, setCompanyDetails] = useState(null);
  const [quantity, setQuantity] = useState('');
  const [orderType, setOrderType] = useState('Market');
  const [productType, setProductType] = useState('Margin');
  const [limitPrice, setLimitPrice] = useState('');
  const [stopLoss, setStopLoss] = useState('');
  const [target, setTarget] = useState('');
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const { authToken } = useAuth();

  const fetchCompanyNames = async (query) => {
    const response = await fetch(process.env.REACT_APP_FETCH_ASSET_NAMES, {
      method: 'POST',
      headers: {
        origin: process.env.REACT_APP_ORIGIN,
        'Content-type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
      },
      body: `{
          "asset-name":"${query}"
        }`
    });
    const data = await response.json();
  
    return data;
  };
  
  const fetchCompanyDetails = async (companyName) => {
    const response = await fetch(process.env.REACT_APP_FETCH_ASSET_DETAILS, {
      method: 'POST',
      headers: {
        origin: process.env.REACT_APP_ORIGIN,
        'Content-type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
      },
      body: `{
          "asset-name":"${companyName}"
        }`
    });
    const data = await response.json();
  
    return data;
  };

  const formatToINR = (value) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
    }).format(value);
  };

  useEffect(() => {
    if (companyQuery.length >= 2) {
      setLoading(true);
      setCompanyNames([]);
      fetchCompanyNames(companyQuery).then((data) => {
        setCompanyNames(data);
        setLoading(false);
      });
    } else {
      setCompanyNames([]);
    }
  }, [companyQuery]);

  const handleCompanySelect = (event, newValue) => {
    setSelectedCompany(newValue);
    if (newValue) {
      fetchCompanyDetails(newValue).then((data) => {
        setCompanyDetails(data);
      });
    }
  };

  const handleQuantityChange = (event) => {
    setQuantity(event.target.value.replace(/[^0-9]/g, ''));
  };

  const handleOrderTypeChange = (event) => {
    setOrderType(event.target.checked ? 'Limit' : 'Market');
  };

  const handleProductTypeChange = (event) => {
    setProductType(event.target.checked ? 'Margin' : 'Delivery');
  };

  const handleLimitPriceChange = (event) => {
    setLimitPrice(event.target.value.replace(/[^0-9.]/g, ''));
  };

  const handleStopLossChange = (event) => {
    setStopLoss(event.target.value.replace(/[^0-9.]/g, ''));
  };

  const handleTargetChange = (event) => {
    setTarget(event.target.value.replace(/[^0-9.]/g, ''));
  };

  const submitForm = async () => {
    if (!selectedCompany || !quantity || (orderType === 'Limit' && (!limitPrice || parseFloat(limitPrice) === 0))) {
      setErrorMessage("Please fill all mandatory fields correctly!!")
    } else if (quantity <= 0) {
      setErrorMessage("Quantity should be more than Zero!!")
    } else {
      setErrorMessage("");
      const orderDetails = {
        "companyName": selectedCompany,
        "quantity": quantity,
        "orderType": orderType,
        "limitPrice": limitPrice,
        "stopLoss": stopLoss,
        "target": target,
        "price": companyDetails.price,
        "productType": "Delivery", //productType
      };
  
      try {
        const response = await fetch(process.env.REACT_APP_CREATE_NEW_ORDER, {
          method: 'POST',
          headers: {
            origin: process.env.REACT_APP_ORIGIN,
            'Content-type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
          },
          body: JSON.stringify(orderDetails),
        });
  
        if (response.ok) {
          const data = await response.json();
          if (data.status === "Success") {
            setSuccessMessage('Order successfully created!');
            setLoading(true);
            setTimeout(() => {
              navigate('/orders');
            }, 3000);
          } else {
            setErrorMessage(data.message);
          }
        } else {
          setErrorMessage('Failed to create order...');
        }
      } catch (error) {
        setErrorMessage('Failed to create order...');
        console.error('Error creating order:', error);
      }
    }
  };

  const capitalRequired = quantity && companyDetails
      ? (orderType === 'Market' ? companyDetails.price : limitPrice) * quantity
      : 0;

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '10vh',
        padding: '20px',
      }}
    >
      <Card
        sx={{
          width: '100%',
          maxWidth: 600,
          padding: 2,
          borderRadius: 8,
          boxShadow: 5,
        }}
      >
        <CardContent>
          <Typography variant="h1" sx={{ fontSize: 24, fontWeight: 'bold', mb: 3 }}>
            Create New Order
          </Typography>
          <Autocomplete
            freeSolo
            options={companyNames}
            onInputChange={(event, newInputValue) => {
              setCompanyQuery(newInputValue);
            }}
            onChange={handleCompanySelect}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Company Name"
                variant="outlined"
                fullWidth
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="inherit" size={20} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  ),
                }}
              />
            )}
          />
          {companyDetails && (
            <div>
              <Typography variant="h2" sx={{ fontSize: 20, fontWeight: 'bold', mt: 3 }}>
                {companyDetails.name}
              </Typography>
              <Typography variant="h2" sx={{ fontSize: 20, fontWeight: 'bold', mt: 1 }}>
                Price: {companyDetails.price}
              </Typography>
            </div>
          )}

          <TextField
            label="Quantity"
            variant="outlined"
            fullWidth
            type="number"
            value={quantity}
            onChange={handleQuantityChange}
            sx={{ mt: 3 }}
          />
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth sx={{ mt: 3 }}>
                <FormLabel component="legend">Margin Buy?</FormLabel>
                <FormControlLabel
                  control={<Switch checked={productType === 'Margin'} onChange={handleProductTypeChange} />}
                  label={productType === 'Margin' ? 'Margin' : 'Delivery'}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl variant="outlined" fullWidth sx={{ mt: 3 }}>
                <FormLabel component="legend">Order Type</FormLabel>
                <FormControlLabel
                  control={<Switch checked={orderType === 'Limit'} onChange={handleOrderTypeChange} />}
                  label={orderType === 'Limit' ? 'Limit' : 'Market'} 
                />
              </FormControl>
            </Grid>
          </Grid>
          {orderType === 'Limit' && (
            <TextField
              label="Limit Price"
              variant="outlined"
              fullWidth
              type="number"
              value={limitPrice}
              onChange={handleLimitPriceChange}
              sx={{ mt: 3 }}
            />
          )}
          <Typography variant="h2" sx={{ fontSize: 20, fontWeight: 'bold', mt: 3 }}>
            Capital Required: {formatToINR(capitalRequired)}
          </Typography>
          <TextField
            label="Stop Loss"
            variant="outlined"
            fullWidth
            type="number"
            value={stopLoss}
            onChange={handleStopLossChange}
            sx={{ mt: 3 }}
          />
          <TextField
            label="Target"
            variant="outlined"
            fullWidth
            type="number"
            value={target}
            onChange={handleTargetChange}
            sx={{ mt: 3 }}
          />
          {successMessage && (
            <Typography variant="h6" sx={{ mt: 3, color: 'green', fontWeight: 'bold' }}>
              {successMessage} {loading ? <CircularProgress color="inherit" size={30} /> : null}
            </Typography>
          )}
          {errorMessage && (
            <Typography variant="h6" sx={{ mt: 3, color: 'maroon', fontWeight: 'bold' }}>
              {errorMessage} {loading ? <CircularProgress color="inherit" size={30} /> : null}
            </Typography>
          )}
          <Button
            variant="contained"
            color="primary"
            fullWidth
            onClick={submitForm}
            sx={{
              mt: 3,
              background: 'linear-gradient(to right, #6a11cb, #2575fc)',
              color: '#fff',
              '&:hover': {
                background: 'linear-gradient(to right, #7b1fa2, #3a7bd5)', // Slightly different gradient for hover
                boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.2)', // Add box shadow for hover effect
              },
            }}
          >
            Submit Order
          </Button>
        </CardContent>
      </Card>
    </Container>
  );
};

export default AdminHome;