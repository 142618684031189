import React, {useState, useEffect, useRef } from 'react';
import { useAuth } from './AuthContext';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@mui/material/Button';

const KiteHome = () => {
  const [tokenExists, setTokenExists] = useState(false);
  const [loading, setLoading] = useState(true);
  const { authToken } = useAuth();
  const formRef = useRef(null);

  const saveRequestToken = async (requestToken) => {
    const response = await fetch(process.env.REACT_APP_SAVE_KITE_TOKEN, {
      method: 'POST',
      headers: {
        origin: process.env.REACT_APP_ORIGIN,
        'Content-type': 'application/json',
        'Authorization': `Bearer ${authToken}`,
      },
      body: JSON.stringify({ request_token: requestToken }),
    });

    if (!response.ok) {
      throw new Error('Failed to save request token!');
    }
    return response.json();
  };

  const fetchData = async () => {
      const response = await fetch(process.env.REACT_APP_GET_USER_KITE_TOKENS, {
          method: 'GET',
          headers: {
              origin: process.env.REACT_APP_ORIGIN,
              'Content-type': 'application/json',
              'Authorization': `Bearer ${authToken}`,
          }
      });
      if (!response.ok) {
        throw new Error('Data coud not be fetched!')
      } else {
        return response.json()
      }
  }

  const submitForm = async (event) => {
    event.preventDefault();
    window.location.href = 'https://kite.zerodha.com/connect/login?v=3&api_key=j57eopk7fwab5y4w';
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const requestToken = urlParams.get('request_token');
    if (requestToken) {
        // Set loading to true when processing the request token
        setLoading(true);
  
        // Save the request token via API
        saveRequestToken(requestToken)
          .then((res) => {
            if (res && res.status && res.status == "SUCCESS") {
              setTokenExists(true);
            } else {
              setTokenExists(false);
            }
            setLoading(false);
          })
          .catch((e) => {
            console.error('Error saving request token:', e);
            setTokenExists(false);
            setLoading(false);
          });
      } else {
        fetchData()
        .then((res) => {
          let responseData = JSON.parse(JSON.stringify(res));
          if (Array.isArray(responseData) && responseData.length > 0 && responseData[0].kiteAccessToken) {
            setTokenExists(true);
          } else {
            setTokenExists(false);
          }
          setLoading(false);
        })
        .catch((e) => {
          console.error('Error fetching data:', e);
          setTokenExists(false);
          setLoading(false);
        });
    }
  }, [authToken]);

  if(loading) {
    return (
      <Container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '60vh',
        }}
      >
        <Card
          sx={{
            width: 300,
            padding: 2,
            borderRadius: 8,
            boxShadow: 5,
          }}
        >
          <CardContent>
            <Typography variant="h1" sx={{ fontSize: 24, fontWeight: 'bold', mb: 2 }}>
              Broker Authentication
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <CircularProgress size={60} thickness={4} />
            </div>
          </CardContent>
        </Card>
      </Container>
    );
  }
  else if(tokenExists) {
      return (
        <Container
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '60vh',
            backgroundColor: (theme) => theme.palette.background.default,
          }}
        >
          <Card
            sx={{
              width: 300,
              padding: 2,
              borderRadius: 8,
              boxShadow: 5,
              textAlign: 'center',
            }}
          >
            <CardContent>
              <Typography variant="h1" sx={{ fontSize: 24, fontWeight: 'bold', color: 'primary.main', mb: 5 }}>
                Broker Authentication
              </Typography>
              <Typography variant="body1" sx={{ fontSize: 18, color: 'text.primary' }}>
                We now have access to your KITE APIs. Thank you...
              </Typography>
            </CardContent>
          </Card>
        </Container>
      );
  }
  else {
      return (
        <Container
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '80vh',
            padding: '20px',
          }}
        >
          <Card
            sx={{
              width: '100%',
              maxWidth: 400,
              padding: 2,
              borderRadius: 8,
              boxShadow: 5,
              marginBottom: 3,
            }}
          >
            <CardContent>
              <Typography variant="h1" sx={{ fontSize: 24, fontWeight: 'bold', mb: 2 }}>
                Broker Authentication
              </Typography>
              <Typography variant="body1" sx={{ mb: 2 }}>
                Click on the below button to login to KITE...
              </Typography>
              <form ref={formRef} onSubmit={submitForm}>
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  onClick={submitForm}
                  sx={{ marginTop: 2 }}
                >
                  KITE Login
                </Button>
              </form>
            </CardContent>
          </Card>
        </Container>
      );
  }
};

export default KiteHome;