import React, { useState, useEffect } from 'react';
import { Container, Typography, Card, CardContent, Grid, Button, Collapse, CardHeader, Box, IconButton, TextField, useMediaQuery } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Drawer, useTheme } from '@mui/material';
import { Edit, Check, Close } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { useAuth } from './AuthContext';

const Orders = () => {
  const [orders, setOrders] = useState([]);
  const [orderUpdates, setOrderUpdates] = useState({});
  const [expandedOrderID, setExpandedOrderID] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const { authToken, userGroups } = useAuth();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('md'));
  const [errorMessage, setErrorMessage] = useState("");
  const [errorDrawerOpen, setErrorDrawerOpen] = useState(false);

  const [editState, setEditState] = useState({});
  const [editValue, setEditValue] = useState({});

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  const formatDate = (date) => {
    const options = { month: 'long', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: true };
    const formatter = new Intl.DateTimeFormat('en-US', options);
  
    const parts = formatter.formatToParts(date);
    const day = date.getDate();
    const suffix = day % 10 === 1 && day !== 11 ? 'st' : day % 10 === 2 && day !== 12 ? 'nd' : day % 10 === 3 && day !== 13 ? 'rd' : 'th';
  
    return `${day}${suffix} ${parts.find(part => part.type === 'month').value} ${parts.find(part => part.type === 'year').value}, ${parts.find(part => part.type === 'hour').value}:${parts.find(part => part.type === 'minute').value} ${parts.find(part => part.type === 'dayPeriod').value}`;
  };

  const calculateDaysDifference = (startDate, endDate) => {
    const oneDay = 24 * 60 * 60 * 1000;
    return Math.round(Math.abs((endDate - startDate) / oneDay));
  };

  const formatToINR = (value) => {
    return new Intl.NumberFormat('en-IN', {
      style: 'currency',
      currency: 'INR',
    }).format(value);
  };
  
  const toggleShowUpdates = async(orderID) => {
    if (expandedOrderID === orderID) {
        setExpandedOrderID(null);
    } else {
        setExpandedOrderID(orderID);
        const response = await fetchOrderUpdatesAPI(orderID);
        setOrderUpdates({ ...orderUpdates, [orderID]: response });
    }
  };

  const calculateTotalInvested = (orders) => {
    return orders.reduce((total, order) => {
      return total + (order.quantity * order.priceAtCreate);
    }, 0);
  };

  const totalInvested = calculateTotalInvested(orders);

  useEffect(() => {
    const fetchOrders = async () => {
      const response = await fetchOrdersAPI();
      setOrders(response);
    };

    fetchOrders();
  }, []);

  const fetchOrderUpdatesAPI = async(orderID) => {
        const orderDetails = {
            "orderID": orderID
        }
        const response = await fetch(process.env.REACT_APP_FETCH_ORDER_UPDATES, {
            method: 'POST',
            headers: {
            origin: process.env.REACT_APP_ORIGIN,
            'Content-type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
            },
            body: JSON.stringify(orderDetails),
        });

        const data = await response.json();

        return data;
  };

  const fetchOrdersAPI = async() => {
    const response = await fetch(process.env.REACT_APP_FETCH_OPEN_ORDERS, {
        method: 'GET',
        headers: {
          origin: process.env.REACT_APP_ORIGIN,
          'Content-type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        }
    });
    const data = await response.json();
    
    return data;
  };

  const handleEditClick = (orderID, field, value) => {
    setEditState({ ...editState, [orderID]: { ...editState[orderID], [field]: true } });
    setEditValue({ ...editValue, [orderID]: { ...editValue[orderID], [field]: value } });
  };

  const handleCancelClick = (orderID, field) => {
    setEditState({ ...editState, [orderID]: { ...editState[orderID], [field]: false } });
  };

  const handleSaveClick = async(orderID, field) => {
    const value = editValue[orderID][field];
    try {
        const orderDetails = {
            "field": field,
            "orderID": orderID,
            "value": value
        }

        if (field == "quantity" && parseInt(value) <= 0) {
          setErrorMessage('Quantity should be greater than Zero. To exit, please click on EXIT ORDER button.');
          setErrorDrawerOpen(true);
        } else {
          const response = await fetch(process.env.REACT_APP_UPDATE_ORDER_DETAILS, {
            method: 'POST',
            headers: {
              origin: process.env.REACT_APP_ORIGIN,
              'Content-type': 'application/json',
              'Authorization': `Bearer ${authToken}`,
            },
            body: JSON.stringify(orderDetails),
          });
    
          if (response.ok) {
              const response = await fetchOrdersAPI();
              setOrders(response);
              setEditState({ ...editState, [orderID]: { ...editState[orderID], [field]: false } });
          } else {
            //setErrorMessage('Failed to create order...');
          }
        }
    } catch (error) {
        //setErrorMessage('Failed to create order...');
        console.error('Error creating order:', error);
    }
  };

  const handleInputChange = (orderID, field, value) => {
    setEditValue({ ...editValue, [orderID]: { ...editValue[orderID], [field]: value } });
  };

  const handleOpenDrawer = (order) => {
    setSelectedOrder(order);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedOrder(null);
  };

  const handleErrorCloseDrawer = () => {
    setErrorDrawerOpen(false);
    setErrorMessage("");
  };

  const handleConfirmExit = async() => {
    if (selectedOrder) {
        try {
            const orderDetails = {
                "orderID": selectedOrder.orderID
            }
    
            const response = await fetch(process.env.REACT_APP_EXIT_ORDER, {
              method: 'POST',
              headers: {
                origin: process.env.REACT_APP_ORIGIN,
                'Content-type': 'application/json',
                'Authorization': `Bearer ${authToken}`,
              },
              body: JSON.stringify(orderDetails),
            });
      
            if (response.ok) {
                handleCloseDrawer();
                const response = await fetchOrdersAPI();
                setOrders(response);
            } else {
              //setErrorMessage('Failed to send exit order message...');
            }
        } catch (error) {
            //setErrorMessage('Failed to send exit order message...');
            console.error('Error creating order:', error);
        }
    }
  };

  const CardHeaderContent = ({ order }) => (
    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Grid container spacing={1}>
            <Grid item xs={12} sm={6}>
                <Typography variant="h6"><strong>{order.tradingsymbol}</strong></Typography>
            </Grid>
            <Grid item xs={12} sm={6} container justifyContent={isSmallScreen ? 'flex-start' : 'flex-end'}>
                <Typography variant="h6" sx={{ alignSelf: 'center' }}>
                    Capital Used: <strong>{formatToINR(order.quantity * order.priceAtCreate)}</strong>
                </Typography>
            </Grid>
        </Grid>
    </Box>
  );

  return (
    <Container sx={{ mt: 4 }}>
        <Grid item xs={12} container alignItems="center" justifyContent="space-between">
            <Typography variant="h5" sx={{ mb: 4 }}>Open Orders</Typography>
            <Typography variant="h6" sx={{ mb: 4 }}>Total Capital: <strong>{formatToINR(totalInvested)}</strong></Typography>
      </Grid>
      {orders.map(order => (
        <Card key={order.orderID} 
          sx={{
            mb: 3,
            border: '1px solid #ccc',
            borderRadius: 2,
            boxShadow: 3,
            padding: 2,
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            '&:hover': {
              transform: 'scale(1.05)',
              boxShadow: '0 4px 20px rgba(0, 0, 0, 0.2)',
            },
          }}
        >
            <CardHeader title={<CardHeaderContent order={order} />} sx={{ borderBottom: '2px solid #000', p: 2 }} />
          <CardContent>
          <Grid container spacing={1} sx={{ mb: 1, borderRadius: 2}}>
            <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>Exchange:</strong> {order.exchange}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>Quantity:</strong>
                {userGroups?.includes('TradeAdmin') ? (
                  editState[order.orderID]?.quantity ? (
                    <>
                      <TextField
                        value={editValue[order.orderID]?.quantity || ''}
                        onChange={(e) => handleInputChange(order.orderID, 'quantity', e.target.value)}
                        size="small"
                        sx={{ width: '100px', mr: 1 }}
                      />
                      <IconButton onClick={() => handleSaveClick(order.orderID, 'quantity')} color="primary">
                        <Check />
                      </IconButton>
                      <IconButton onClick={() => handleCancelClick(order.orderID, 'quantity')} color="secondary">
                        <Close />
                      </IconButton>
                    </>
                  ) : (
                    <>
                      {order.quantity}
                      <IconButton onClick={() => handleEditClick(order.orderID, 'quantity', order.quantity)} sx={{ ml: 1 }}>
                        <Edit fontSize="small" />
                      </IconButton>
                    </>
                  )
                ) : (
                  <>
                    {order.quantity}
                  </>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>Order/Product Type:</strong> {order.orderType} / {order.productType}
                </Typography>
            </Grid>
            {order.limitPrice && (
                <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Limit Price:</strong>
                    {userGroups?.includes('TradeAdmin') ? (
                      editState[order.orderID]?.limitPrice ? (
                    <>
                    <TextField
                        value={editValue[order.orderID]?.limitPrice || ''}
                        onChange={(e) => handleInputChange(order.orderID, 'limitPrice', e.target.value)}
                        size="small"
                        sx={{ width: '100px', mr: 1 }}
                    />
                    <IconButton onClick={() => handleSaveClick(order.orderID, 'limitPrice')} color="primary">
                        <Check />
                    </IconButton>
                    <IconButton onClick={() => handleCancelClick(order.orderID, 'limitPrice')} color="secondary">
                        <Close />
                    </IconButton>
                    </>
                    ) : (
                        <>
                        {order.limitPrice}
                        {order.orderType === 'Limit' && (
                            <IconButton onClick={() => handleEditClick(order.orderID, 'limitPrice', order.limitPrice)} sx={{ ml: 1 }}>
                            <Edit fontSize="small" />
                            </IconButton>
                        )}
                        </>
                    )): (
                      <>
                        {order.limitPrice}
                      </>
                    )}
                </Typography>
                </Grid>
            )}
            {order.stopLoss && (
                <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Stop Loss:</strong>
                    {userGroups?.includes('TradeAdmin') ? (
                      editState[order.orderID]?.stopLoss ? (
                    <>
                      <TextField
                        value={editValue[order.orderID]?.stopLoss || ''}
                        onChange={(e) => handleInputChange(order.orderID, 'stopLoss', e.target.value)}
                        size="small"
                        sx={{ width: '100px', mr: 1 }}
                      />
                      <IconButton onClick={() => handleSaveClick(order.orderID, 'stopLoss')} color="primary">
                        <Check />
                      </IconButton>
                      <IconButton onClick={() => handleCancelClick(order.orderID, 'stopLoss')} color="secondary">
                        <Close />
                      </IconButton>
                    </>
                    ) : (
                        <>
                        {order.stopLoss}
                        <IconButton onClick={() => handleEditClick(order.orderID, 'stopLoss', order.stopLoss)} sx={{ ml: 1 }}>
                            <Edit fontSize="small" />
                        </IconButton>
                        </>
                    )): (
                      <>
                        {order.stopLoss}
                      </>
                    )}
                </Typography>
                </Grid>
            )}
            {order.target && (
                <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                    <strong>Target:</strong>
                    {userGroups?.includes('TradeAdmin') ? (
                      editState[order.orderID]?.target ? (
                    <>
                      <TextField
                        value={editValue[order.orderID]?.target || ''}
                        onChange={(e) => handleInputChange(order.orderID, 'target', e.target.value)}
                        size="small"
                        sx={{ width: '100px', mr: 1 }}
                      />
                      <IconButton onClick={() => handleSaveClick(order.orderID, 'target')} color="primary">
                        <Check />
                      </IconButton>
                      <IconButton onClick={() => handleCancelClick(order.orderID, 'target')} color="secondary">
                        <Close />
                      </IconButton>
                    </>
                    ) : (
                        <>
                        {order.target}
                        <IconButton onClick={() => handleEditClick(order.orderID, 'target', order.target)} sx={{ ml: 1 }}>
                            <Edit fontSize="small" />
                        </IconButton>
                        </>
                    )): (
                      <>
                        {order.target}
                      </>
                    )}
                </Typography>
                </Grid>
            )}
            <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>Order Price:</strong> {order.priceAtCreate}
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Typography variant="body1" sx={{ mb: 1 }}>
                <strong>Order Created At:</strong> {formatDate(new Date(order.orderedAt))} (<strong>{calculateDaysDifference(new Date(order.orderedAt), new Date())}</strong> Days)
                </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
                <Button
                variant="outlined"
                color="info"
                onClick={() => toggleShowUpdates(order.orderID)}
                sx={{ mt: 1 }}
                >
                {expandedOrderID === order.orderID ? 'Hide Updates' : 'Show Updates'}
                </Button>
            </Grid>
            {userGroups?.includes('TradeAdmin') && (
              <Grid item xs={12} sm={6}>
                  <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleOpenDrawer(order)}
                  sx={{
                      mt: 1,
                      background: 'linear-gradient(to right, #e53935, #d32f2f)',
                      color: '#fff',
                      '&:hover': {
                        background: 'linear-gradient(to right, #d32f2f, #c62828)',
                      },
                    }}
                  >
                  Exit Order
                  </Button>
              </Grid>
            )}


            <Grid item xs={12}>
                <Collapse in={expandedOrderID === order.orderID}>
                    {orderUpdates[order.orderID]?.length > 0 && (
                        <TableContainer component={Paper} sx={{ mt: 2 }}>
                            <Table size="small" aria-label="order updates table">
                                <TableHead sx={{ backgroundColor: '#f5f5f5' }}>
                                <TableRow>
                                    <TableCell sx={{ borderBottom: '1px solid #ccc' }}>Updated At</TableCell>
                                    <TableCell sx={{ borderBottom: '1px solid #ccc' }}>Update Type</TableCell>
                                    <TableCell sx={{ borderBottom: '1px solid #ccc' }}>Quantity</TableCell>
                                    <TableCell sx={{ borderBottom: '1px solid #ccc' }}>Order Type</TableCell>
                                    <TableCell sx={{ borderBottom: '1px solid #ccc' }}>Limit Price</TableCell>
                                    <TableCell sx={{ borderBottom: '1px solid #ccc' }}>Stop Loss</TableCell>
                                    <TableCell sx={{ borderBottom: '1px solid #ccc' }}>Target</TableCell>
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {orderUpdates[order.orderID].map((update, index) => (
                                    <TableRow key={index}>
                                    <TableCell sx={{ borderBottom: '1px solid #ccc' }}>{formatDate(new Date(update.updatedAt))}</TableCell>
                                    <TableCell sx={{ borderBottom: '1px solid #ccc' }}>{update.type}</TableCell>
                                    <TableCell sx={{ borderBottom: '1px solid #ccc' }}>{update.quantity}</TableCell>
                                    <TableCell sx={{ borderBottom: '1px solid #ccc' }}>{update.orderType}</TableCell>
                                    <TableCell sx={{ borderBottom: '1px solid #ccc' }}>{update.limitPrice}</TableCell>
                                    <TableCell sx={{ borderBottom: '1px solid #ccc' }}>{update.stopLoss}</TableCell>
                                    <TableCell sx={{ borderBottom: '1px solid #ccc' }}>{update.target}</TableCell>
                                    </TableRow>
                                ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    )}


                    
                </Collapse>
            </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}

        <Drawer
            anchor="bottom"
            open={drawerOpen}
            onClose={handleCloseDrawer}
            sx={{
                '& .MuiDrawer-paper': {
                  padding: 2,
                  width: isLargeScreen ? '50%' : '100%',
                  margin: isLargeScreen ? 'auto' : '0',
                },
              }}
        >
            <Box>
            <Typography variant="h6" sx={{ mb: 2 }}>
                Confirm Exit Order
            </Typography>
            <Typography variant="body1" sx={{ mb: 2 }}>
                Are you sure you want to exit the position for {selectedOrder?.tradingsymbol}?
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={6}>
                <Button
                    variant="contained"
                    fullWidth
                    onClick={handleCloseDrawer}
                    sx={{ background: 'linear-gradient(to right, #6a11cb, #2575fc)', color: '#fff' }}
                >
                    No
                </Button>
                </Grid>
                <Grid item xs={6}>
                <Button
                    variant="contained"
                    fullWidth
                    onClick={handleConfirmExit}
                    sx={{ background: 'linear-gradient(to right, #6a11cb, #2575fc)', color: '#fff' }}
                >
                    Yes
                </Button>
                </Grid>
            </Grid>
            </Box>
        </Drawer>
        <Drawer
          anchor="bottom"
          open={errorDrawerOpen}
          onClose={handleErrorCloseDrawer}
          sx={{
            '& .MuiDrawer-paper': {
              padding: 2,
              width: isLargeScreen ? '50%' : '100%',
              margin: isLargeScreen ? 'auto' : '0',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center'
            },
          }}
        >
          <IconButton
            onClick={handleErrorCloseDrawer}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              color: 'red', // Close icon color to match error theme
            }}
          >
            <CloseIcon />
          </IconButton>
          <Typography
            variant="body1"
            sx={{ 
              mb: 2, 
              color: 'red', // Set text color to red
            }}
          >
            {errorMessage}
          </Typography>
        </Drawer>
    </Container>
  );
};

export default Orders;
