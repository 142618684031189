import React, { useEffect, useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, useTheme, useMediaQuery } from '@mui/material';
import { useAuth } from './AuthContext';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';

const UserLogins = () => {
  const [logins, setLogins] = useState([]);
  const { authToken } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    const fetchLogins = async () => {
      try {
        const response = await fetch(process.env.REACT_APP_FETCH_USER_LOGINS, {
          method: 'GET',
          headers: {
            origin: process.env.REACT_APP_ORIGIN,
            'Content-type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
          },
        });
        const data = await response.json();
        setLogins(data);
      } catch (error) {
        console.error('Error fetching login data:', error);
      }
    };

    fetchLogins();
  }, [authToken]);

  const formatDate = (dateString) => {
    const options = { weekday: 'long', day: 'numeric', month: 'long' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const formatTime = (dateString) => {
    const options = { hour: '2-digit', minute: '2-digit', hour12: true };
    return new Date(dateString).toLocaleTimeString(undefined, options);
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Logins
      </Typography>
      <Grid container spacing={2}>
        {logins.map((login, index) => (
          <Grid item xs={12} sm={6} md={4} key={login.tokenDate}>
            <Card
              sx={{
                border: '2px solid',
                borderColor: index % 2 === 0 ? '#9370DB': '#A52A2A',
                borderRadius: '8px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                transition: 'transform 0.2s, box-shadow 0.2s',
                '&:hover': {
                  transform: 'scale(1.05)',
                  boxShadow: '0 8px 16px rgba(0, 0, 0, 0.2)',
                },
              }}
            >
              <CardContent
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <CalendarTodayIcon sx={{ fontSize: '2rem', mb: 1 }} />
                <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
                  {formatDate(login.tokenDate)}
                </Typography>
                <AccessTimeIcon sx={{ fontSize: '2rem', mb: 1 }} />
                <Typography sx={{ color: 'text.secondary' }}>
                  {formatTime(login.tokenDate)}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default UserLogins;
