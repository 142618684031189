import React from 'react';
import { Container } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import ResponsiveAppBar from './ResponsiveAppBar';
import LoginCheck from './LoginCheck';
import Home from './Home';
import Dashboard from './Dashboard'
import { AuthProvider } from './AuthContext'
import ProtectedRoute from './ProtectedRoute';
import UserHome from './UserHome';
import AdminHome from './AdminHome';
import Orders from './Orders';
import UserLogins from './UserLogins';
import AdminLogins from './AdminLogins';
import TradeBook from './TradeBook';
import UserOrders from './UserOrders';
import KiteHome from './KiteHome';
import BreezeHome from './BreezeHome';

function App() {
  return (
    <Router>
      <AuthProvider>
        <Container>
          <ResponsiveAppBar></ResponsiveAppBar>
          <Container style={{ marginTop: '20px' }}>
            <Routes>
              <Route path="/" element={<LoginCheck />} />
              <Route path="/Home" element={<ProtectedRoute element={<Home />} />} />
              <Route path="/ahome" element={<ProtectedRoute element={<AdminHome />} requiredGroups={["TradeAdmin"]} />} />
              <Route path="/orders" element={<ProtectedRoute element={<Orders />} requiredGroups={["TradeAdmin", "Operations"]} />} />
              <Route path="/logins" element={<ProtectedRoute element={<AdminLogins />} requiredGroups={["TradeAdmin", "Operations"]} />} />
              <Route path="/trade-book" element={<ProtectedRoute element={<TradeBook />} requiredGroups={["TradeAdmin", "Operations"]} />} />
              <Route path="/Dashboard" element={<Dashboard />} />
              <Route path="/uhome" element={<ProtectedRoute element={<UserHome />} />} />
              <Route path="/khome" element={<ProtectedRoute element={<KiteHome />} />} />
              <Route path="/bhome" element={<ProtectedRoute element={<BreezeHome />} />} />
              <Route path="/user-logins" element={<ProtectedRoute element={<UserLogins />} />} />
              <Route path="/user-orders" element={<ProtectedRoute element={<UserOrders />} />} />
            </Routes>
          </Container>
        </Container>
      </AuthProvider>
    </Router>
  );
}

export default App;
