// src/AuthContext.js
import React, { createContext, useState, useContext, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

// Create a Context for Auth
const AuthContext = createContext();

// Create a provider component
export const AuthProvider = ({ children }) => {
  const [authToken, setAuthTokenState] = useState(localStorage.getItem('authToken'));
  const navigate = useNavigate();
  const [userGroups, setUserGroups] = useState(() => {
    const groups = localStorage.getItem('userGroups');
    return groups ? JSON.parse(groups) : [];
  });

  const isTokenExpired = () => {
    if (!authToken) return true;
    const decoded = jwtDecode(authToken);
    const expirationTime = decoded.exp * 1000;
    return Date.now() >= expirationTime;
  };

  // Function to set the token in both state and localStorage
  const setAuthToken = (token) => {
    localStorage.setItem('authToken', token);
    setAuthTokenState(token);
    if (token) {
      const decoded = jwtDecode(token);
      localStorage.setItem('userGroups', decoded['cognito:groups'] || []);
      const groups = decoded['cognito:groups'] || [];
      localStorage.setItem('userGroups', JSON.stringify(groups));
      setUserGroups(groups);
    }
  };

  const logout = () => {
    localStorage.removeItem('authToken');
    localStorage.removeItem('userGroups');
    setAuthTokenState(null);
    setUserGroups([]);
  };

  // Function to parse the access token from the URL
  const parseAccessTokenFromUrl = () => {
    const hash = window.location.hash;
    const params = new URLSearchParams(hash.replace('#', ''));
    return params.get('access_token');
  };

  useEffect(() => {
    const token = parseAccessTokenFromUrl();
    if (token) {
      setAuthToken(token);
      window.history.replaceState({}, document.title, window.location.pathname);
    } else if (authToken && isTokenExpired()) {
      logout();
      navigate('/');
    }
  }, [authToken, userGroups]);

  return (
    <AuthContext.Provider value={{ authToken, setAuthToken, logout, userGroups }}>
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use the Auth context
export const useAuth = () => {
  return useContext(AuthContext);
};
